import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const alert = {
  success,
  error,
  // clear,
};

function success(message) {
  toast.success(message)
}

function error(message) {
  toast.error(message)
}
