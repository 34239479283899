import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../_actions/auth.actions";
import { status } from "../../_constants";
import { useNavigate } from "react-router-dom";
import { alert } from "../../utils/commonFunction/alert";

function Registration(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const [credentials, setCredentials] = useState({
    first_name: "",
    last_name: "",
    email: "",
    gender: "",
    mobile_number: "",
    nationality: "",
    address: "",
    city: "",
    postcode: "",
    ethnicity: "",
    dob: "",
    national_insurance: "",
    birth_town: "",
    course: "",
  });


  const [isValidate, setIsValidate] = useState(false);

  const RegisterData = useSelector((state) => state.auth)

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Postcode field
    if (name === "postcode") {
      // Remove any non-alphanumeric characters
      const cleanedValue = value.replace(/[^a-zA-Z0-9\s]/g, "");

      // Restrict to 8 characters
      const formattedValue = cleanedValue.slice(0, 8);

      // Update the state with the formatted postcode
      setCredentials({ ...credentials, [name]: formattedValue });
    }

    // Mobile Number field
    else if (name === "mobile_number") {
      const cleanedValue = value.replace(/\D/g, "");
      const formattedValue = cleanedValue.slice(0, 11);
      setCredentials({ ...credentials, [name]: formattedValue });
    }

    // Date of Birth (dob) field
    else if (name === "dob") {
      // Remove any non-digit characters from the input
      const cleanedValue = value.replace(/\D/g, "");

      let formattedDate = '';
      for (let i = 0; i < cleanedValue.length; i++) {
        if (i === 2 || i === 4) {
          formattedDate += '-';
        }
        formattedDate += cleanedValue.charAt(i);
      }

      const formattedValue = formattedDate.slice(0, 10);
      setCredentials({ ...credentials, [name]: formattedValue });
    } else {
      setCredentials({ ...credentials, [name]: value });
    }
  };

  useEffect(() => {
    if (RegisterData && RegisterData.get_registration_status === status.SUCCESS) {
      setCredentials({
        first_name: "",
        last_name: "",
        email: "",
        gender: "",
        mobile_number: "",
        nationality: "",
        address: "",
        city: "",
        postcode: "",
        ethnicity: "",
        dob: "",
        national_insurance: "",
        birth_town: "",
        course: "",
      })
      alert.success("Registration Success.!!")
      navigate("/thank-you")
    }
    // console.log("user data ", RegisterData.get_registration_data);
  }, [RegisterData])

  const validateCredentials = (isValidate) => {
    function isDateValid(dateStr) {
      return !isNaN(new Date(dateStr));
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const mobileRegex = /^\d{11}$/;
    const postcodeRegex = /^[a-zA-Z0-9\s]{0,8}$/;
    const validObj = {
      message: "",
      isValid: true,
    };

    let isValid = true;
    const retData = {
      first_name: validObj,
      last_name: validObj,
      email: validObj,
      gender: validObj,
      mobile_number: validObj,
      nationality: validObj,
      address: validObj,
      city: validObj,
      postcode: validObj,
      ethnicity: validObj,
      dob: validObj,
      national_insurance: validObj,
      birth_town: validObj,
      course: validObj,
      isValid,
    };

    if (isValidate) {
      const {
        first_name,
        last_name,
        email,
        gender,
        mobile_number,
        nationality,
        address,
        city,
        postcode,
        ethnicity,
        dob,
        national_insurance,
        birth_town,
        course,
      } = credentials;

      if (!first_name) {
        retData.first_name = {
          message: "First name is required",
          isValid: false,
        };
        isValid = false;
      } else if (first_name && first_name.length <= 2) {
        retData.first_name = {
          message: "First name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!last_name) {
        retData.last_name = {
          message: "Last name is required",
          isValid: false,
        };
        isValid = false;
      } else if (last_name && last_name.length <= 2) {
        retData.last_name = {
          message: "Last name must be at least 3 characters",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!emailRegex.test(email)) {
        retData.email = {
          message: "Enter a valid email address",
          isValid: false,
        };
        isValid = false;
      }
      if (!gender) {
        retData.gender = {
          message: "Please select your Gender",
          isValid: false,
        };
        isValid = false;
      }

      if (!mobile_number) {
        retData.mobile_number = {
          message: "Mobile Number is required",
          isValid: false,
        };
        isValid = false;
      } else if (!mobileRegex.test(mobile_number)) {
        retData.mobile_number = {
          message: "Please enter a valid mobile number",
          isValid: false,
        };
        isValid = false;
      }

      if (!address) {
        retData.address = {
          message: "Address is required",
          isValid: false,
        };
        isValid = false;
      }

      if (!city) {
        retData.city = {
          message: "City is required",
          isValid: false,
        };
        isValid = false;
      }

      if (!nationality) {
        retData.nationality = {
          message: "Please select your Nationality",
          isValid: false,
        };
        isValid = false;
      }

      if (!postcode) {
        retData.postcode = {
          message: "Postcode is required",
          isValid: false,
        };
        isValid = false;
      } else if (!postcodeRegex.test(postcode)) {
        retData.postcode = {
          message: "Enter a valid postcode",
          isValid: false,
        };
        isValid = false;
      }
      if (!ethnicity) {
        retData.ethnicity = {
          message: "Please enter your Ethnicity",
          isValid: false,
        };
        isValid = false;
      }

      if (!dob) {
        retData.dob = {
          message: "Date of Birth is required",
          isValid: false,
        };
        isValid = false;
      }

      if (!national_insurance) {
        retData.national_insurance = {
          message: "National Insurance is required",
          isValid: false,
        };
        isValid = false;
      } else if (national_insurance && national_insurance.length <= 8) {
        retData.national_insurance = {
          message: "Enter a valid National Insurance",
          isValid: false,
        };
        isValid = false;
      }

      if (!birth_town) {
        retData.birth_town = {
          message: "Birth Town is required",
          isValid: false,
        };
        isValid = false;
      }

      if (!course) {
        retData.course = {
          message: "Course is required",
          isValid: false,
        };
        isValid = false;
      }
      retData.isValid = isValid;
      return retData;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsValidate(true)
    const errorData = validateCredentials(true)

    if (errorData.isValid) {
      dispatch(authActions.userRegister(credentials))
    }

  };

  const errorData = validateCredentials(isValidate)
  return (
    <div className="main-wrapper">
      <div className="registration-section">
        <div className="container">
          <div className="registration-text">
            <h2>Ready to Upskill with Upnup?</h2>
          </div>
          <div className="registration-section-inner">
            <div className="form-group form-group-blue">
              <input
                type="text"
                name="first_name"
                className="form-control"
                placeholder="First name"
                value={credentials.first_name}
                onChange={handleChange}
              />
              {errorData && errorData.first_name.message != "" && (
                <div className="error-message">{errorData.first_name.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="text"
                name="last_name"
                className="form-control"
                placeholder="Last name"
                value={credentials.last_name}
                onChange={handleChange}
              />
              {errorData && errorData.last_name.message != "" && (
                <div className="error-message">{errorData.last_name.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <select
                id="gender"
                name="gender"
                value={credentials.gender}
                onChange={handleChange}
                className="custom-select"
              >
                <option value="" disabled>
                  Gender
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
              {errorData && errorData.gender.message != "" && (
                <div className="error-message">{errorData.gender.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="email"
                name="email"
                className="form-control"
                placeholder="Email"
                value={credentials.email}
                onChange={handleChange}
              />
              {errorData && errorData.email.message != "" && (
                <div className="error-message">{errorData.email.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="text"
                name="mobile_number"
                className="form-control"
                placeholder="Mobile Number"
                value={credentials.mobile_number}
                onChange={handleChange}
              />
              {errorData && errorData.mobile_number.message != "" && (
                <div className="error-message">{errorData.mobile_number.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <select
                id="gender"
                name="nationality"
                value={credentials.nationality}
                onChange={handleChange}
                className="custom-select"
              >
                <option value="" disabled>
                  Nationality
                </option>
                <option value="us">United States</option>
                <option value="uk">United Kingdom</option>
                <option value="ca">Canada</option>
                <option value="fr">France</option>
                <option value="Afghanistan">Afghanistan</option>
                <option value="Germany">Germany</option>
                <option value="japan">Japan</option>
                <option value="American">American</option>
              </select>
              {errorData && errorData.nationality.message != "" && (
                <div className="error-message">{errorData.nationality.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="text"
                name="address"
                className="form-control"
                placeholder="Address"
                value={credentials.address}
                onChange={handleChange}
              />
              {errorData && errorData.address.message != "" && (
                <div className="error-message">{errorData.address.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="City"
                name="city"
                className="form-control"
                placeholder="City"
                value={credentials.city}
                onChange={handleChange}
              />
              {errorData && errorData.city.message != "" && (
                <div className="error-message">{errorData.city.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="Postcode"
                name="postcode"
                className="form-control"
                placeholder="Postcode"
                value={credentials.postcode}
                onChange={handleChange}
              />
              {errorData && errorData.postcode.message != "" && (
                <div className="error-message">{errorData.postcode.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="ethnicity"
                name="ethnicity"
                placeholder="Ethnicity"
                className="form-control"
                value={credentials.ethnicity}
                onChange={handleChange}
              />
              {errorData && errorData.ethnicity.message != "" && (
                <div className="error-message">{errorData.ethnicity.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="Date of birth"
                name="dob"
                className="form-control"
                placeholder="DD-MM-YYYY"
                value={credentials.dob}
                onChange={handleChange}
              />
              {errorData && errorData.dob.message != "" && (
                <div className="error-message">{errorData.dob.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="text"
                name="national_insurance"
                className="form-control"
                placeholder="National Insurance"
                value={credentials.national_insurance}
                onChange={handleChange}
              />
              {errorData && errorData.national_insurance.message != "" && (
                <div className="error-message">{errorData.national_insurance.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="text"
                name="birth_town"
                className="form-control"
                placeholder="Town of Birth"
                value={credentials.birth_town}
                onChange={handleChange}
              />
              {errorData && errorData.birth_town.message != "" && (
                <div className="error-message">{errorData.birth_town.message}</div>
              )}
            </div>
            <div className="form-group form-group-blue">
              <input
                type="text"
                name="course"
                className="form-control"
                placeholder="Course"
                value={credentials.course}
                onChange={handleChange}
              />
              {errorData && errorData.course.message != "" && (
                <div className="error-message">{errorData.course.message}</div>
              )}
            </div>
            <div className="yes-ready-btn">
              <button
                type="text"
                title="Yes, I’m Ready"
                className="btn-primary"
                onClick={handleSubmit}
              >
                Yes, I’m Ready
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
