import { Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Home from "./pages/Home";
import ThankYou from "./pages/Thank-you";
import Registration from "./pages/Registration";
import "./utils/style/style.scss";
import "bootstrap/dist/css/bootstrap.css";
import { Provider } from "react-redux";
import { store } from "./_store";
import { ToastContainer } from "react-toastify";


function App() {
  return (
    <Provider store={store}>

      <div className="App">
        <ToastContainer
          position={"top-right"}
          autoClose={3000}
          hideProgressBar={true}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="thank-you" element={<ThankYou />} />
          <Route path="registration" element={<Registration />} />
        </Routes>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
