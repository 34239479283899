import React from "react";
import thankyou from "../../utils/images/thank-you.svg";
import whatsApp from "../../utils/images/whatsApp.svg";

function ThankYou (props) {
    return (
        <div className="thank-you">
            <div className="container">
                <div className="thank-you-inner">
                    <img src={thankyou} alt="" />
                    <div className="thank-you-text">
                        <h3>Thank You for Joining Upnup!</h3>
                        <p>We are thrilled that you’ve taken the step to upskill yourself with our educational courses.
                            Your commitment to growth and learning is inspiring, and we are here to support you every
                            step of the way. </p>
                        <p> We look forward to connecting with you soon and helping you achieve your
                            goals.</p>
                        <p> Welcome to Upnup!</p>
                    </div>
                </div>
                <div className="thank-you-box">
                    <div className="thank-you-content">
                        <h2>Tell a friend. Share by WhatsApp now!</h2>
                        <p>Do you know anyone who would love to upskill themselves? Tell them about Upnup.</p>
                    </div>
                    <div className="social-icons">
                        <a href="https://api.whatsapp.com/send?phone=+919999999999" target="_blank">
                            <img src={whatsApp} />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ThankYou;