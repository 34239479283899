import React, { useState } from "react";
import homebannerimg from "../../utils/images/home-banner-img.png";
import bgimg from "../../utils/images/bg-img.svg";
import accessimg from "../../utils/images/access-img.svg";
import manchesterimg from "../../utils/images/manchester-img.svg";
import birminghamimg from "../../utils/images/birmingham-img.svg";
import leedsimg from "../../utils/images/leeds-img.svg";
import londonimg from "../../utils/images/london-img.svg";
import newcastleimg from "../../utils/images/newcastle-img.svg";
import line from "../../utils/images/Line 1.svg";
import line2 from "../../utils/images/Line 2.svg";
import application from "../../utils/images/application-icon.svg";
import massageicon from "../../utils/images/massage-icon.svg";
import processingicon from "../../utils/images/processing-icon.svg";
import trainingicon from "../../utils/images/accept-icon.svg";
import receiveicon from "../../utils/images/receive-icon.svg";
import abdul from "../../utils/images/abdul.png";
import staricon from "../../utils/images/star-icon.svg";
import Hannah from "../../utils/images/hannah.png";
import location1 from "../../utils/images/location-icon1.svg";
import location2 from "../../utils/images/location-icon2.svg";
import location3 from "../../utils/images/location-icon3.svg";
import location4 from "../../utils/images/location-icon4.svg";
import location5 from "../../utils/images/location-icon5.svg";
import samirraja from "../../utils/images/samir-raja.png";

function Home(props) {
    const [activeIndex, setActiveIndex] = useState(0);
    const items = ['Business', 'Management', 'Finance', 'Healthcare', 'Construction', 'Digital', 'Tourism'];
    const [isActive, setIsActive] = useState(0);

    const handleItemClick = (index) => {
        setActiveIndex(index);
    };
    const toggleActive = (index) => {
        setIsActive(index);
    };

    return (
        <div className="main-wrapper">
            <div className="home-banner">
                <div className="container">
                    <div className="banner-img">
                        <img src={homebannerimg} />
                        <div className="banner-img-css"></div>
                        <div className="bg-banner">
                            <img src={bgimg} />
                            <div className="bg-img-css"></div>
                        </div>
                        <div className="banner-text">
                            <h2 className="title">Upskilling you with education</h2>
                            <div className="register-btn">
                                <a href="/registration">
                                    <button type="text" title="Register Now" className="btn-primary">Register Now</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="our-courses">
                        <div className="our-courses-text">
                            <h2>Our Courses</h2>
                        </div>
                        <nav id="navbar">
                            <ul className="list">
                                {items.map((item, index) => (
                                    <li
                                        key={index}
                                        className={index === activeIndex ? 'active' : ''}
                                        onClick={() => handleItemClick(index)}
                                    >
                                        {item}
                                    </li>
                                ))}
                            </ul>
                        </nav>
                        <div className="swiper-container">
                            <div className="swiper">
                                <div className="row">
                                    <div className="col-lg-2 col-6 first">
                                        {activeIndex === 0 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Business and Tourism Management with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 first">
                                        {activeIndex === 0 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BA (Hons) Business and Enterprise with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 first">
                                        {activeIndex === 0 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>MA International Business</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 first">
                                        {activeIndex === 0 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Global Business Management with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 first">
                                        {activeIndex === 0 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HNC/HND in Business</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 first">
                                        {activeIndex === 0 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HND in Business</p>
                                            </div>
                                        </div>}
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-6 second">
                                        {activeIndex === 1 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Accounting & Financial Management with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 second">
                                        {activeIndex === 1 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Construction Management with Foundation Years</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 second">
                                        {activeIndex === 1 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HND in Construction Management for England (Construction Design and Build Technology)</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 second">
                                        {activeIndex === 1 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Construction Management with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 second">
                                        {activeIndex === 1 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HND in Construction Management for England (Construction Design and Build Technology)</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 second">
                                        {activeIndex === 1 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HND in Construction Management for England (Construction Design and Build Technology)</p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-6 third">
                                        {activeIndex === 3 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Health and Social Care with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 third">
                                        {activeIndex === 3 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HNC/HND Healthcare Practice for England</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 third">
                                        {activeIndex === 3 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Health, Wellbeing and Social Care with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 third">
                                        {activeIndex === 3 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Health and Social Care with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 third">
                                        {activeIndex === 3 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HND Healthcare Practice for England</p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-6 fourth">
                                        {activeIndex === 2 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Accounting and Financial Management</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 fourth">
                                        {activeIndex === 2 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Accounting and Financial Management</p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-6 fifth">
                                        {activeIndex === 4 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Construction Management with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 fifth">
                                        {activeIndex === 4 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Construction Management with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-6 sixth">
                                        {activeIndex === 5 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HND in Digital Technologies for England (Cyber Security)</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 sixth">
                                        {activeIndex === 5 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>HND in Digital Technologies for England (Cyber Security)</p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-2 col-6 seventh">
                                        {activeIndex === 6 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Construction Management with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="col-lg-2 col-6 seventh">
                                        {activeIndex === 6 && <div className="our-courses-box">
                                            <div className="our-courses-box-text">
                                                <p>BSc (Hons) Construction Management with Foundation Year</p>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="nationwide-access">
                    <div className="container">
                        <div className="nationwide-access-inner">
                            <div className="nationwide-access-text">
                                <h2>Nationwide Access</h2>
                            </div>
                            <div className="nationwide-access-section">
                                <div className="nationwide-access-boxs">
                                    <div id="nationwide-access-content" className={`element ${isActive === 0 ? 'active' : ''}`} onClick={() => toggleActive(0)}>
                                        <img src={manchesterimg} />
                                        <div className="nationwide-access-content-text">
                                            <p>Manchester</p>
                                        </div>
                                    </div>
                                    <div id="nationwide-access-content" className={`element ${isActive === 1 ? 'active' : ''}`} onClick={() => toggleActive(1)}>
                                        <img src={birminghamimg} />
                                        <div className="nationwide-access-content-text">
                                            <p>Birmingham</p>
                                        </div>
                                    </div>
                                    <div id="nationwide-access-content" className={`element ${isActive === 2 ? 'active' : ''}`} onClick={() => toggleActive(2)}>
                                        <img src={leedsimg} />
                                        <div className="nationwide-access-content-text">
                                            <p>Leeds</p>
                                        </div>
                                    </div>
                                    <div id="nationwide-access-content" className={`element ${isActive === 3 ? 'active' : ''}`} onClick={() => toggleActive(3)}>
                                        <img src={londonimg} />
                                        <div className="nationwide-access-content-text">
                                            <p>London</p>
                                        </div>
                                    </div>
                                    <div id="nationwide-access-content" className={`element ${isActive === 4 ? 'active' : ''}`} onClick={() => toggleActive(4)}>
                                        <img src={newcastleimg} />
                                        <div className="nationwide-access-content-text">
                                            <p>Newcastle</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="nationwide-access-img">
                                    <img src={accessimg} className="access-img d-none d-lg-block" />
                                    <div className="access-img-mobile"></div>
                                    <div className="social-icons">
                                        <ul>
                                            {isActive === 4 && <li className={`element fourth ${isActive === 4 ? 'active' : ''}`} onClick={() => toggleActive(4)}><img src={location1} />Newcastle</li>}
                                            {isActive === 2 && <li className={`element second ${isActive === 2 ? 'active' : ''}`} onClick={() => toggleActive(2)}><img src={location2} />Leeds</li>}
                                            {isActive === 0 && <li className={`element giro ${isActive === 0 ? 'active' : ''}`} onClick={() => toggleActive(0)}><img src={location3} />Manchester</li>}
                                            {isActive === 1 && <li className={`element first ${isActive === 1 ? 'active' : ''}`} onClick={() => toggleActive(1)}><img src={location4} />Birmingham</li>}
                                            {isActive === 3 && <li className={`element third ${isActive === 3 ? 'active' : ''}`} onClick={() => toggleActive(3)}><img src={location5} />London</li>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="it-works-section">
                    <div className="container">
                        <div className="it-works-section-inner">
                            <div className="it-works-section-text">
                                <h2>How it Works</h2>
                            </div>
                            <div className="Works-section">
                                <img src={line} className="line d-none d-md-block" />
                                <img src={line} className="line2 d-none d-md-block" />
                                <img src={line2} className="line d-block d-md-none" />
                                <img src={line2} className="line2 d-block d-md-none" />
                                <div className="it-works-section-img">
                                    <img src={application} />
                                    <div className="it-works-content">
                                        <p>Application Submitted</p>
                                    </div>
                                </div>
                                <div className="it-works-section-img">
                                    <img src={massageicon} />
                                    <div className="it-works-content">
                                        <p>Complete Interview</p>
                                    </div>
                                </div>
                                <div className="it-works-section-img">
                                    <img src={processingicon} />
                                    <div className="it-works-content">
                                        <p>Processing Application</p>
                                    </div>
                                </div>
                                <div className="it-works-section-img">
                                    <img src={trainingicon} />
                                    <div className="it-works-content">
                                        <p>Accept Offer</p>
                                    </div>
                                </div>
                                <div className="it-works-section-img">
                                    <img src={receiveicon} />
                                    <div className="it-works-content">
                                        <p>Enrol and Receive Student Funding</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="testimonials-section">
                    <div className="container">
                        <div className="testimonials-section-inner">
                            <div className="testimonials-section-text">
                                <h2>Testimonials</h2>
                            </div>
                            <div className="testimonials-section-boxs">
                                <div className="testimonials-section-box">
                                    <div className="testimonials-img">
                                        <img src={abdul} />
                                        <div className="testimonials-content">
                                            <h4>Abdul Qasim</h4>
                                            <p>Business Owner</p>
                                        </div>
                                    </div>
                                    <div className="testimonials-star-icon">
                                        <img src={staricon} />
                                        <p>a week ago</p>
                                    </div>
                                    <div className="testimonials-text">
                                        <p>Thanks to Upnup, I earned a degree in business management, which allowed me to
                                            transition from driving a taxi to managing my own transportation company. The
                                            skills and knowledge I gained have been invaluable in starting and running my
                                            business. Upskilling through Upnup has truly transformed my career and my life!
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-section-box">
                                    <div className="testimonials-img">
                                        <img src={samirraja} />
                                        <div className="testimonials-content">
                                            <h4>Samir Raza</h4>
                                            <p>Finance and Accounts</p>
                                        </div>
                                    </div>
                                    <div className="testimonials-star-icon">
                                        <img src={staricon} />
                                        <p>a year ago</p>
                                    </div>
                                    <div className="testimonials-text">
                                        <p>Upnup’s accounting and financial management foundation year was a game-changer
                                            for my career. With my new credentials, I moved to a new role within my
                                            organisation and hope to open my own restaurant in the future. The advanced
                                            techniques and management skills I learned have been instrumental in my success.
                                            Upskilling through Upnup has opened doors I never thought possible.
                                        </p>
                                    </div>
                                </div>
                                <div className="testimonials-section-box">
                                    <div className="testimonials-img">
                                        <img src={Hannah} />
                                        <div className="testimonials-content">
                                            <h4>Hannah McDermot</h4>
                                            <p>Online Business</p>
                                        </div>
                                    </div>
                                    <div className="testimonials-star-icon">
                                        <img src={staricon} />
                                        <p>6 months ago</p>
                                    </div>
                                    <div className="testimonials-text">
                                        <p>As a stay-at-home mum, finding time for personal growth was challenging. Upnup’s
                                            flexible degree with 2 days a week in digital marketing allowed me to study at
                                            my own pace. Now, I’ve launched my own successful online business, thanks to the
                                            comprehensive education I received. Upskilling through UpnUp has empowered me to
                                            create a new career path.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
};

export default Home;