import { authConstants, status } from "../_constants";

const initialState = {};

export function auth(state = initialState, action) {
  console.log(state);
  switch (action.type) {
    //   User registration reducers
    case authConstants.REGISTER_USER_REQUEST:
      return {
        ...state,
        get_registration_status: status.IN_PROGRESS,
        get_registration_data: action.data,
      };
    case authConstants.REGISTER_USER_SUCCESS:
      return {
        ...state,
        get_registration_status: status.SUCCESS,
        get_registration_data: action.data,
      };
    case authConstants.REGISTER_USER_FAILURE:
      return {
        ...state,
        get_registration_status: status.FAILURE,
        get_registration_data: action.data,
      };
    default:
      return state;
  }
}
