import React from "react";
import footerlogo from '../../utils/images/footer-logo.svg';
import heart from '../../utils/images/heart.svg';

function Footer (props) {
    return (
        <div className="footer">
            <div className="container">
                <div className="footer-inner">
                    <div className="footer-logo">
                        <a href="/">
                            <img src={footerlogo} />
                        </a>
                    </div>
                    <div className="copy-right-text">
                        <p>© 2024 Upnup. All rights reserved.</p>
                    </div>
                    <div className="loyalty-text">
                        made with
                        <em><i className="fa fa-spinner fa-spin"></i></em>
                        by
                        <a href="https://softorigin.co.uk/" target="_blank"> Soft Origin</a>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Footer;