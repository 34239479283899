export const commonFunctions = {
  getRequestoptions,
};

function getRequestoptions(type, extraHeaders, body) {
  let authHeader = {};
  let request = {
    method: type,
    headers: {
      ...extraHeaders,
      ...authHeader,
    },
  };
  if (body) {
    request["body"] = body;
  }
  return request;
}
