import { config } from "../config/config";
import { commonFunctions } from "../utils/commonFunction/index";
export const authService = {
  userRegister,
};

function userRegister(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };

  const request = commonFunctions.getRequestoptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(`${config.localApiUrl}/api/register.php`, request).then(
    (response) => response
  );
  // if (!response.ok) {
  //   throw new Error("Network response was not ok");
  // }
  // return response.json();
  // .catch((error) => {
  //   console.error("Error calling userRegister API:", error);
  //   throw error;
  // });
}

// import { config } from "../config/config";
// import { commonFunctions } from "../utils/commonFunction/index";

// export const authService = {
//   userRegister,
// };

// async function userRegister(data) {
//   const extraHeaders = {
//     "Content-Type": "application/json",
//   };

//   const request = commonFunctions.getRequestoptions(
//     "POST",
//     extraHeaders,
//     JSON.stringify(data)
//   );

//   try {

//     const response = await fetch(`${config.localApiUrl}/api/register`, request);

//     if (!response.ok) {
//       throw new Error(`HTTP error! Status: ${response.status}`);
//     }

//     const responseData = await response.json();
//     return responseData;
//   } catch (error) {

//     console.error("Error:", error);
//     throw error; // Rethrow or handle the error as needed in your application
//   }
// }
