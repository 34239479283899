import React from "react";
import headerlogo from '../../utils/images/header-logo.png';

function Header (props) {
    return (
        <div className="header fixed-tab">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="logo">
                            <a href="/">
                                <img src={headerlogo} alt="logo" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Header;