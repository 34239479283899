import { authConstants } from "../_constants";
import { authService } from "../_services/auth.service";
import { alert } from "../utils/commonFunction/alert";

export const authActions = {
  userRegister,
};

function userRegister(data) {
  return (dispatch) => {
    dispatch(
      dispatchFuncion({
        type: authConstants.REGISTER_USER_REQUEST,
        data: null,
      })
    );
    authService.userRegister(data)
      .then(async (response) => {
        if (response.status == 200) {
          dispatch(
            dispatchFuncion({
              type: authConstants.REGISTER_USER_SUCCESS,
              data: await response.json(),
            })
          );
          alert.success(response.message);
        } else {
          dispatch(
            dispatchFuncion({
              type: authConstants.REGISTER_USER_FAILURE,
              data: await response.json(),
            })
          );
          alert.error(response.message);
        }
      },
        (error) => {
          dispatch(
            dispatchFuncion({
              type: authConstants.REGISTER_USER_FAILURE,
              data: error.message,
            })
          );
          alert.error(error.message);
        }
      );
  };
}
function dispatchFuncion(data) {
  return {
    type: data.type,
    data: data.data,
  };
}
